import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";

import logo from '../../assets/imgs/slide2.png'
import {useState} from "react";
import {useAuthStore} from "../../store/user-store";
import {baseApi} from "../../api/axios";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";


export default function ConfirmPasswordComponent() {
    const {t} = useTranslation()
    const redirect = useNavigate()
    const [loading, setLoading] = useState(false)
    const [secure, setSecure] = useState('password')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const authStore = useAuthStore();
    const [error, setError] = useState('')
    const [params] = useSearchParams ();

    const login = async () => {
        setLoading(true)

        const data = new FormData()
        data.append('password', password)
        data.append('confirm', confirmPassword)
    data.append('token', params.get('token') ?? '');

        try {
            setError(' ')
            const response = await baseApi.post('/confirm-password', data,{
                headers: {
                    'Accept': 'application/json'
                }
            })
            console.log(response.data)
            if (response?.data?.status === 'success') {
                toast.success(t('success-reset-password'))
                redirect('/login')
            }
        } catch (e) {
           // console.log(e.response?.data?.message)
            // toast.error();
            setError(e.response?.data?.message??e.response?.data?.error)
        }
        setLoading(false)

    }

    const changeSecure = () => {
        if(secure==='password'){
            setSecure('text');
        }else{
            setSecure('password');
        }

    }

    return (
        <div className={'container'}>
            <div className="row justify-content-center align-items-center">

                <div className="col-md-8 d-flex justify-content-center align-items-center">
                    <div className="card-login">
                        <div className="d-flex flex-column justify-content-center align-items-center card-auth"
                             style={{flex: '1 0 auto'}}>
                            <div className={'logo-container'}><img className={'logo-auth'}
                                                                   src={authStore.settings?.logo ?? logo} alt=""/></div>


                            <h2 className={'text-primary my-4'}>{t('reset-password')}</h2>


                            <div className={'position-relative password-container w-100'}>
                                <input type={secure} onChange={(e) => setPassword(e.target.value)}
                                       className="input-form input my-2" placeholder={t('password')}
                                       autoComplete={'new-password'}/>
                                <i onClick={changeSecure} className="fa fa-eye position-absolute text-primary"/>
                            </div>
                            <div className={'position-relative password-container w-100'}>
                                <input type={secure} onChange={(e) => setConfirmPassword(e.target.value)}
                                       className="input-form input my-2" placeholder={t('confirm-password')}
                                       autoComplete={'new-password'}/>
                                <i onClick={changeSecure} className="fa fa-eye position-absolute text-primary"/>
                            </div>


                            {error.length > 0 ? <span className=" my-2 text-error d-block ">{error??''}</span> : null}
                            {!loading ?
                                <button onClick={login} className="btn d-block btn-sm btn-buy ">{t('send')}</button> :
                                <button className="btn d-block btn-sm submit-btn ">{t('loading')}</button>}
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )


}