import {Link, useNavigate} from "react-router-dom";

import logo from '../../assets/imgs/slide2.png'
import {useState} from "react";
import {useAuthStore} from "../../store/user-store";
import {baseApi} from "../../api/axios";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";


export default function ResetPasswordComponent() {
    const {t} = useTranslation()
    const redirect = useNavigate()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const authStore = useAuthStore();
    const [error, setError] = useState('')


    const login = async () => {
        setLoading(true)
        setError('')
        const data = new FormData()
        data.append('email', email)
        try {
            const response = await baseApi.post('/reset-password', data)
            if (response?.data?.status === 'success') {
                toast.success(t('send-email'))
            }
        } catch (e) {
            toast.error(e.response?.data?.error);
            // setError(e.response.data?.error)
        }
        setLoading(false)

    }
    return (
        <div className={'container'}>
            <div className="row justify-content-center align-items-center">

                <div className="col-md-8 d-flex justify-content-center align-items-center">
                    <div className="card-login">
                        <div className="d-flex flex-column justify-content-center align-items-center card-auth"
                             style={{flex: '1 0 auto'}}>
                            <div className={'logo-container'}><img className={'logo-auth'}
                                                                   src={authStore.settings?.logo ?? logo} alt=""/></div>


                            <h2 className={'text-primary my-4'}>{t('reset-password')}</h2>


                            <input type="email" onChange={(e) => setEmail(e.target.value)} value={email}
                                   className="input-form input my-2" placeholder={t('email')}
                                   autoComplete={'off'}/>

                            {error.length > 0 ? <span className=" my-2 text-error d-block ">{error}</span> : null}
                            {!loading ?
                                <button onClick={login} className="btn d-block btn-sm btn-buy ">{t('send')}</button> :
                                <button className="btn d-block btn-sm submit-btn ">{t('loading')}</button>}

                            <Link to={'/login'}
                                  className="btn d-block btn-sm text-black my-2">{t('return')}</Link>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )


}